
import Vue from 'vue';

import BaseAppBar from '@/entities/app/base-app-bar.vue';

export default Vue.extend({
  name: 'ErrorAppBarWidget',

  components: {
    BaseAppBar,
  },
});
