
import Vue from 'vue';
import { Models } from '@/injectables/tokens';
import AppBar from '@/widgets/error/error-page-app-bar.vue';
import { ErrorsModelContract, ErrorInfo } from '@/injectables';
import { Routes } from '@/router/routes';

export default Vue.extend({
  name: 'ErrorPage',

  useInjectable: [Models.Errors],

  components: {
    AppBar,
  },

  computed: {
    errorInfo(): ErrorInfo {
      return (this.errorsEntity as ErrorsModelContract).getErrorsInfo(this.statusCode);
    },
    statusCode(): string {
      return this.$route.query.code || '404';
    },
    errorHeader(): string {
      return this.errorInfo.header;
    },
    mainText(): string {
      return this.errorInfo.text;
    },
    containerClass(): string {
      return this.$vuetify.breakpoint.mdAndDown ? 'error-container-small' : 'error-container';
    },
  },

  methods: {
    goBack() {
      if (window.history.length > 2) {
        return this.$router.go(-1);
      }
      this.$router.push({ name: Routes.Home });
    },
  },
});
